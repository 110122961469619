<template>
  <div>
    <div id="output" ref="output"></div>
    <div id="print" ref="print">
      <v-container class="text-center pa-0" v-if="!empty(event)">
        <v-card :loading="loading" class="mx-auto" max-width="420">
          <template slot="progress">
            <v-progress-linear
              color="deep-purple"
              height="10"
              indeterminate
            ></v-progress-linear>
          </template>

          <v-img
            crossorigin="anonymous"
            height="250"
            :src="imUrl + 'events/' + event.logo_path"
            id="event_logo"
            ref="event_logo"
          ></v-img>

          <v-card-title>{{ event.name }}</v-card-title>

          <v-card-text>
            <!--<v-row align="center" class="mx-0">
          <v-rating
            :value="4.5"
            color="amber"
            dense
            half-increments
            readonly
            size="14"
          ></v-rating>

          <div class="grey--text ms-4">4.5 (413)</div>
        </v-row>-->

            <!--<div class="my-4 text-subtitle-1">{{ event.fare }}</div>-->

            <div>
              {{ event.intro_text }}
            </div>
          </v-card-text>

          <v-divider class="mx-4"></v-divider>

          <template v-if="!completed">
            <v-card-title class="text-center">
              <div style="width: 100%">
                {{ formatDate(event.from_date, "MMM DD, yyyy") }}
                {{ " to " }}
                {{ formatDate(event.to_date, "MMM DD, yyyy") }}
              </div>
            </v-card-title>
            <v-card-text>
              <div style="width: 100%">
                <v-chip color="green" text-color="white">
                  {{ event.start_time.substring(0, 5) }}
                  {{ " to " }}
                  {{ event.end_time.substring(0, 5) }}
                </v-chip>
              </div>
            </v-card-text>

            <v-divider class="mx-4"></v-divider>

            <div class="pt-3 pb-3">{{ event.custom_header }}</div>

            <v-form
              v-if="event.status_id != -1"
              ref="form"
              v-model="valid"
              lazy-validation
              class="mr-3 ml-3"
            >
              <v-radio-group v-model="visitor_type" :column="false">
                <v-radio
                  v-for="v in visitorTypes"
                  :key="v.id"
                  :label="v.visitor_type"
                  :value="v.id"
                ></v-radio>
              </v-radio-group>

              <v-text-field
                v-model="name"
                :rules="nameRules"
                label="Name"
                required
              ></v-text-field>

              <v-text-field v-model="email" label="E-mail"></v-text-field>

              <v-text-field
                v-model="mobile"
                :rules="mobileRules"
                label="Mobile Number"
                required
              ></v-text-field>

              <v-text-field
                v-if="visitor_type == 'Trade Visitor'"
                :rules="designationRules"
                v-model="designation"
                label="Designation"
                required
              ></v-text-field>
              <v-text-field
                v-else
                v-model="designation"
                label="Designation"
                required
              ></v-text-field>

              <v-text-field
                v-if="visitor_type == 'Trade Visitor'"
                :rules="companyRules"
                v-model="company"
                label="Company"
                required
              ></v-text-field>
              <v-text-field
                v-else
                v-model="company"
                label="Company"
                required
              ></v-text-field>

              <div class="text-left">Remind me on the day of the Event</div>
              <v-checkbox
                v-model="notify_text"
                label="Via Text Message"
              ></v-checkbox>

              <v-btn
                :disabled="!valid"
                color="success"
                class="mr-4"
                @click="makeRSVP"
              >
                Register
              </v-btn>
            </v-form>

            <v-card-title>Event Finished</v-card-title>
          </template>
          <template v-else>
            <v-card-subtitle class="text-center">
              <div>
                <img
                  width="150px"
                  crossorigin="anonymous"
                  class="el-upload-list__item-thumbnail"
                  :src="imUrl + 'bookings/' + bookings_id + '.png'"
                  alt="QR Code"
                />
              </div>
              {{ event.booking_confirmed_message }}
              <div>
                <v-btn
                  :disabled="!valid"
                  class="mr-4"
                  id="print_button"
                  ref="print_button"
                  depressed
                  color="primary"
                  small
                  @click.stop.prevent="print"
                >
                  Save as Image
                </v-btn>
              </div>
            </v-card-subtitle>
          </template>
          <v-divider class="mx-4"></v-divider>
          <div class="mt-3 mb-10 pt-3 pb-5">{{ event.custom_footer }}</div>
        </v-card>
      </v-container>
    </div>
  </div>
</template>
<script>
import { imageUrl } from "../../utils/repo";
import html2canvas from "html2canvas";
export default {
  created() {
    this.getEventDetails();
  },
  data() {
    return {
      event: {
        name: "View Event Registration",
        fare: "FREE ENTRANCE",
        status_id: -1,
        intro_text: "",
        date: "",
        start_time: "",
        end_time: "",
        custom_footer: "",
        custom_header: "",
        booking_confirmed_message: "",
        logo_path: "",
      },
      visitorTypes: [
        { id: "General Visitor", visitor_type: "General Visitor" },
        { id: "Trade Visitor", visitor_type: "Trade Visitor" },
      ],
      bookings_id: "",
      completed: false,
      loading: false,
      valid: false,
      notify_text: true,
      notify_email: true,
      name: "",
      visitor_type: "General Visitor",
      nameRules: [(v) => !!v || "Name is required"],
      designation: "",
      designationRules: [(v) => !!v || "Designation is required"],
      company: "",
      companyRules: [(v) => !!v || "Companys is required"],
      email: "",
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      mobile: "",
      mobileRules: [
        (v) => !!v || "Mobile Number is required",
        (v) => (v > 7000000 && v < 9999999) || "Mobile Number is invalid",
      ],
    };
  },
  methods: {
    getEventDetails() {
      let f = {
        codes_id: this.$route.params.id,
        slug: this.$route.params.slug,
      };
      this.loading = true;
      this.api("rsvp", f)
        .then((response) => {
          console.log(response);
          this.loading = false;
          this.event = response.data;
          this.event.fare = "FREE ENTRANCE";
        })
        .finally(() => {
          this.loading = false;
        });
    },
    makeRSVP() {
      this.$refs.form.validate();
      if (!this.valid) return;
      if (this.empty(this.name) || this.empty(this.mobile)) return;
      if (this.visitor_type == "Trade Visitor") {
        if (this.empty(this.designation) || this.empty(this.company)) return;
      }
      let f = {
        events_id: this.event.id,
        event_codes_id: this.$route.params.id,
        event_fares_id: this.event.fares[0].id,
        event_dates_id: this.event.dates[0].id,
        name: this.name,
        email: this.email,
        mobile_no: this.mobile,
        company: this.company,
        notify_text: this.notify_text,
        notify_email: this.notify_email,
      };
      this.loading = true;
      this.api("make-rsvp", f)
        .then((response) => {
          console.log(response);
          this.loading = false;
          if (response.status == true) {
            this.event = response.data;
            this.bookings_id = response.bookings_id;
            this.completed = true;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async print() {
      document.getElementById("print_button").style.display = "none";
      let self = this;
      html2canvas(document.getElementById("print"), {
        allowTaint: true,
        useCORS: true,
      }).then(function (canvas) {
        canvas.id = "h2canvas";
        canvas.ref = "h2canvas";
        document.getElementById("output").appendChild(canvas);
        console.log(canvas.toDataURL);
        //self.saveAs(canvas.toDataURL(), "file-name.png");
        self.out(canvas);
      });
    },
    async out(canvas) {
      document.getElementById("print").style.display = "none";
      const printCanvas = canvas; // el.innerHTML; // await html2canvas(el, options);

      const link = document.createElement("a");
      link.setAttribute("download", "download.png");
      link.setAttribute(
        "href",
        printCanvas
          .toDataURL("image/png")
          .replace("image/png", "image/octet-stream")
      );
      link.click();

      console.log("done");
    },
  },
  name: "Public",
  computed: {
    imUrl() {
      return `${imageUrl}`;
    },
  },
};
</script>
<style>
.theme--light.v-application {
  background: #aba9a9 !important;
}
.v-radio {
  margin-right: 20px;
}
</style>
